import { hasNoPadding, SpacingWrapper } from '@finn/platform-modules';
import { useGetFeature } from '@finn/ua-featureflags';
import { ErrorBoundary } from '@finn/ui-components';
import React, { memo } from 'react';

import CosmicModule from '~/ui-modules/CosmicModule';

import { ContentExperimentMetadata } from './ContentExperimentMetadata';

const ContentExperimentComponent: React.FC<{
  data: ContentExperimentMetadata;
}> = ({
  data: { experiment: moduleExperiment, variant_a, variant_b, variant_c },
}) => {
  const variant = useGetFeature(moduleExperiment);

  const childModules =
    variant === 'b' ? variant_b : variant === 'c' ? variant_c : variant_a;

  const children = childModules?.map((module) => (
    <ErrorBoundary key={module.id}>
      <SpacingWrapper
        key={module.slug}
        noPaddingTop={hasNoPadding(module.type)}
        html_id={module.metadata?.html_id}
      >
        <CosmicModule data={module} />
      </SpacingWrapper>
    </ErrorBoundary>
  ));

  return <>{children}</>;
};

export const ContentExperiment = memo(ContentExperimentComponent);
